/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

const PAGE_RELOAD_DELAY_IN_MS = 3000

if (!window.ynd_env) {
  console.error('Failed to fetch ENVs variables!')

  setTimeout(() => window.location.reload(), PAGE_RELOAD_DELAY_IN_MS)
} else {
  import('./app/initializeApp')
}
